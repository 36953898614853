<template>
  <div class="expPersonal">
    <h3  style="margin-top: 50px">Опыт сотрудников</h3>

    <!-- АРТАМКИН АЛЕКСЕЙ ВЯЧЕСЛАВОВИЧ -->
    <div class="card mb-3">
      <div class="row no-gutters">
        <div class="col-md-4 infoCard">
          <i class="fas fa-user-circle"></i>
          <h4 class="card-title" style="margin-top: 20px">Артамкин Алексей Вячеславович</h4>
          <p class="card-text text-muted">Генеральный директор</p>
        </div>
        <div class="col-md-8">
          <div class="card-body row">
            <div class="col-md-6">
              <h5>Объекты</h5>
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th scope="col">Объект</th>
                    <th scope="col">Работы</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>ЖК «Северная звезда»</td>
                    <td class="text-left">
                      <ul class="listExp">
                        <li>Программирование и наладка автоматики общеобменной вентиляции</li>
                        <li>Программирование и наладка автоматики ИТП</li>
                        <li>Программирование и наладка автоматики тепловых завес</li>
                        <li>Программирование и наладка автоматики системы дренажных насосов</li>
                        <li>Наладка сети диспетчеризации</li>
                        <li>Программирование и наладка системы диспетчеризации</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>ЖК «Триумф Палас»</td>
                    <td class="text-left">
                      <ul class="listExp">
                        <li>Программирование и наладка автоматики общеобменной вентиляции</li>
                        <li>Программирование и наладка автоматики ИТП</li>
                        <li>Программирование и наладка автоматики тепловых завес</li>
                        <li>Программирование и наладка автоматики системы дренажных насосов</li>
                        <li>Наладка сети диспетчеризации</li>
                        <li>Программирование и наладка системы диспетчеризации</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>ЖК «Алые паруса» <br>5 и 6 корпус</td>
                    <td class="text-left">
                      <ul class="listExp">
                        <li>Программирование и наладка автоматики общеобменной вентиляции</li>
                        <li>Программирование и наладка автоматики ИТП</li>
                        <li>Программирование и наладка автоматики тепловых завес</li>
                        <li>Программирование и наладка автоматики системы дренажных насосов</li>
                        <li>Наладка сети диспетчеризации</li>
                        <li>Программирование и наладка системы диспетчеризации</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>ЖК «Измайловский» <br>
                      1 очередь
                    </td>
                    <td class="text-left">
                      <ul class="listExp">
                        <li>Программирование и наладка автоматики общеобменной вентиляции</li>
                        <li>Программирование и наладка автоматики ИТП</li>
                        <li>Программирование и наладка автоматики тепловых завес</li>
                        <li>Программирование и наладка автоматики системы дренажных насосов</li>
                        <li>Наладка сети диспетчеризации</li>
                        <li>Программирование и наладка системы диспетчеризации</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>ЖК «Бирюзова 33»</td>
                    <td class="text-left">
                      <ul class="listExp">
                        <li>Программирование и наладка автоматики общеобменной вентиляции</li>
                        <li>Программирование и наладка автоматики ИТП</li>
                        <li>Программирование и наладка автоматики тепловых завес</li>
                        <li>Программирование и наладка автоматики системы дренажных насосов</li>
                        <li>Наладка сети диспетчеризации</li>
                        <li>Программирование и наладка системы диспетчеризации</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>ЖК «Дом на Беговой»<br>
                      1 и 2 очередь
                    </td>
                    <td class="text-left">
                      <ul class="listExp">
                        <li>Программирование и наладка автоматики общеобменной вентиляции</li>
                        <li>Программирование и наладка автоматики ИТП</li>
                        <li>Программирование и наладка автоматики тепловых завес</li>
                        <li>Программирование и наладка автоматики системы дренажных насосов</li>
                        <li>Наладка сети диспетчеризации</li>
                        <li>Программирование и наладка системы диспетчеризации</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>ЖК «Дом на Смоленской набережной»</td>
                    <td class="text-left">
                      <ul class="listExp">
                        <li>Программирование и наладка автоматики общеобменной вентиляции</li>
                        <li>Программирование и наладка автоматики ИТП</li>
                        <li>Программирование и наладка автоматики тепловых завес</li>
                        <li>Программирование и наладка автоматики системы дренажных насосов</li>
                        <li>Наладка сети диспетчеризации</li>
                        <li>Программирование и наладка системы диспетчеризации</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>ЖК «Новая Остоженка»</td>
                    <td class="text-left">
                      <ul class="listExp">
                        <li>Программирование и наладка автоматики общеобменной вентиляции</li>
                        <li>Программирование и наладка автоматики ИТП</li>
                        <li>Программирование и наладка автоматики тепловых завес</li>
                        <li>Программирование и наладка автоматики системы дренажных насосов</li>
                        <li>Наладка сети диспетчеризации</li>
                        <li>Программирование и наладка системы диспетчеризации</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>ЖК «Седьмое Небо»</td>
                    <td class="text-left">
                      <ul class="listExp">
                        <li>Программирование и наладка автоматики ИТП</li>
                        <li>Программирование и наладка автоматики тепловых завес</li>
                        <li>Программирование и наладка автоматики системы дренажных насосов</li>
                        <li>Наладка сети диспетчеризации</li>
                        <li>Программирование и наладка системы диспетчеризации</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>ЖК «Эльсинор»</td>
                    <td class="text-left">
                      <ul class="listExp">
                        <li>Программирование и наладка автоматики общеобменной вентиляции</li>
                        <li>Программирование и наладка автоматики ИТП</li>
                        <li>Программирование и наладка автоматики тепловых завес</li>
                        <li>Программирование и наладка автоматики системы дренажных насосов</li>
                        <li>Наладка сети диспетчеризации</li>
                        <li>Программирование и наладка системы диспетчеризации</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>ЖК «Дом в Сокольниках»</td>
                    <td class="text-left">
                      <ul class="listExp">
                        <li>Программирование и наладка автоматики общеобменной вентиляции</li>
                        <li>Программирование и наладка автоматики ИТП</li>
                        <li>Программирование и наладка автоматики тепловых завес</li>
                        <li>Программирование и наладка автоматики системы дренажных насосов</li>
                        <li>Наладка сети диспетчеризации</li>
                        <li>Программирование и наладка системы диспетчеризации</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Офис ДОН-СТРОЙ на ул. Мосфильмовская, д. 70</td>
                    <td class="text-left">
                      <ul class="listExp">
                        <li>Программирование и наладка автоматики общеобменной вентиляции</li>
                        <li>Наладка сети диспетчеризации</li>
                        <li>Программирование и наладка системы диспетчеризации</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>ТЦ «На Беговой»</td>
                    <td class="text-left">
                      <ul class="listExp">
                        <li>Программирование и наладка автоматики общеобменной вентиляции</li>
                        <li>Программирование и наладка автоматики ИТП</li>
                        <li>Программирование и наладка автоматики тепловых завес</li>
                        <li>Программирование и наладка автоматики системы дренажных насосов</li>
                        <li>Программирование и наладка автоматики второго контура чиллерной системы</li>
                        <li>Программирование и наладка автоматики системы фенкойлов</li>
                        <li>Программирование и наладка автоматики внутреннего освещения</li>
                        <li>Программирование и наладка автоматики наружного и рекламного освещения</li>
                        <li>Программирование и наладка автоматики клапанов ОЗК</li>
                        <li>Наладка сети диспетчеризации</li>
                        <li>Программирование и наладка системы диспетчеризации</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>ТЦ «Европа» (2-я очередь)<br>г. Калининград</td>
                    <td class="text-left">
                      <ul class="listExp">
                        <li>Программирование и наладка автоматики общеобменной вентиляции</li>
                        <li>Программирование и наладка автоматики ИТП</li>
                        <li>Программирование и наладка автоматики второго контура чиллерной системы</li>
                        <li>Программирование и наладка автоматики системы фенкойлов</li>
                        <li>Программирование и наладка автоматики наружного освещения</li>
                        <li>Наладка сети диспетчеризации</li>
                        <li>Программирование и наладка системы диспетчеризации</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>ул.Новый Арбат, вл.32, Гостиница</td>
                    <td class="text-left">
                      <ul class="listExp">
                        <li>Программирование и наладка системы АСТУЭ</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>ТРК «MARi»</td>
                    <td class="text-left">
                      <ul class="listExp">
                        <li>Программирование и наладка автоматики общеобменной вентиляции</li>
                        <li>Программирование и наладка автоматики второго контура чиллерной системы из 7 чиллерных машин</li>
                        <li>Программирование и наладка автоматики системы дренажных насосов</li>
                        <li>Программирование и наладка автоматики внутреннего освещения</li>
                        <li>Программирование и наладка автоматики наружного освещения</li>
                        <li>Наладка сети диспетчеризации</li>
                        <li>Программирование и наладка системы диспетчеризации</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Реконструкция выставочного комплекса.<br> г.Уфа, ул.Менделеева, 158</td>
                    <td class="text-left">
                      <ul class="listExp">
                        <li>Программирование и наладка автоматики общеобменной вентиляции</li>
                        <li>Программирование и наладка автоматики второго контура чиллерной системы</li>
                        <li>Наладка сети диспетчеризации</li>
                        <li>Программирование и наладка системы диспетчеризации</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>г. Москва, ЮЗАО, район Коньково, ул. Бутлерова, 17</td>
                    <td class="text-left">
                      <ul class="listExp">
                        <li>Программирование и наладка автоматики общеобменной вентиляции</li>
                        <li>Программирование и наладка автоматики второго контура чиллерной системы</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>ЖК «Арт Резиденс»<br> г. Москва, 3-я ул. Ямского Поля, дом 9</td>
                    <td class="text-left">
                      <ul class="listExp">
                        <li>Наладка сети диспетчеризации</li>
                        <li>Программирование и наладка системы диспетчеризации</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>РЕКОНСТРУКЦИЯ БАЗЫ ОТДЫХА<br> Краснодарский край, г. Сочи, Центральный район, ул. Политехническая, д.66</td>
                    <td class="text-left">
                      <ul class="listExp">
                        <li>Программирование и наладка автоматики общеобменной вентиляции</li>
                        <li>Программирование и наладка автоматики ИТП</li>
                        <li>Программирование и наладка автоматики системы дренажных насосов</li>
                        <li>Программирование и наладка автоматики второго контура чиллерной системы (чиллер АБХМ с влажным охладителем)</li>
                        <li>Наладка сети диспетчеризации</li>
                        <li>Программирование и наладка системы диспетчеризации</li>
                      </ul>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-md-6">
              <h5>Сертификаты</h5>
              <div id="certificateSlideAA" class="carousel-style carousel-fade carousel slide" data-ride="carousel" data-pause="false">
                <div class="carousel-inner" data-toggle="modal" data-target="#certificateModalAA">
                  <div class="carousel-item active">
                    <img src="../assets/members/artamkin/certificate/certificate1.jpg" class="d-block w-100 certificate" alt="...">
                  </div>
                  <div class="carousel-item">
                    <img src="../assets/members/artamkin/certificate/certificate2.jpg" class="d-block w-100 certificate" alt="...">
                  </div>
                  <div class="carousel-item">
                    <img src="../assets/members/artamkin/certificate/certificate3.jpg" class="d-block w-100 certificate" alt="...">
                  </div>
                  <div class="carousel-item">
                    <img src="../assets/members/artamkin/certificate/certificate4.jpg" class="d-block w-100 certificate" alt="...">
                  </div>
                  <div class="carousel-item">
                    <img src="../assets/members/artamkin/certificate/certificate5.jpg" class="d-block w-100 certificate" alt="...">
                  </div>
                  <div class="carousel-item">
                    <img src="../assets/members/artamkin/certificate/certificate3.jpg" class="d-block w-100 certificate" alt="...">
                  </div>
                </div>
                <!-- Модальное окно Сертификатов -->
                <div class="modal fade" id="certificateModalAA" tabindex="-1" role="dialog" aria-labelledby="certificateModalLabelAA" aria-hidden="true">
                  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div class="modal-content">
                      <div class="modal-body">
                        <div id="modalCertificateSlideAA" class="carousel-fade carousel slide" data-ride="carousel">
                          <ol class="carousel-indicators">
                            <li data-target="#modalCertificateSlideAA" data-slide-to="0" class="active"></li>
                            <li data-target="#modalCertificateSlideAA" data-slide-to="1"></li>
                            <li data-target="#modalCertificateSlideAA" data-slide-to="2"></li>
                            <li data-target="#modalCertificateSlideAA" data-slide-to="3"></li>
                            <li data-target="#modalCertificateSlideAA" data-slide-to="4"></li>
                            <li data-target="#modalCertificateSlideAA" data-slide-to="5"></li>
                            <li data-target="#modalCertificateSlideAA" data-slide-to="6"></li>
                            <li data-target="#modalCertificateSlideAA" data-slide-to="7"></li>
                            <li data-target="#modalCertificateSlideAA" data-slide-to="8"></li>
                            <li data-target="#modalCertificateSlideAA" data-slide-to="9"></li>
                            <li data-target="#modalCertificateSlideAA" data-slide-to="10"></li>
                            <li data-target="#modalCertificateSlideAA" data-slide-to="11"></li>
                            <li data-target="#modalCertificateSlideAA" data-slide-to="12"></li>
                            <li data-target="#modalCertificateSlideAA" data-slide-to="13"></li>
                            <li data-target="#modalCertificateSlideAA" data-slide-to="14"></li>
                            <li data-target="#modalCertificateSlideAA" data-slide-to="15"></li>
                          </ol>
                          <div class="carousel-inner">
                            <div class="carousel-item active">
                              <img src="../assets/members/artamkin/certificate/certificate1.jpg" class="d-block w-100" alt="...">
                            </div>
                            <div class="carousel-item">
                              <img src="../assets/members/artamkin/certificate/certificate2.jpg" class="d-block w-100" alt="...">
                            </div>
                            <div class="carousel-item">
                              <img src="../assets/members/artamkin/certificate/certificate3.jpg" class="d-block w-100" alt="...">
                            </div>
                            <div class="carousel-item">
                              <img src="../assets/members/artamkin/certificate/certificate4.jpg" class="d-block w-100" alt="...">
                            </div>
                            <div class="carousel-item">
                              <img src="../assets/members/artamkin/certificate/certificate5.jpg" class="d-block w-100" alt="...">
                            </div>
                            <div class="carousel-item">
                              <img src="../assets/members/artamkin/certificate/certificate6.jpg" class="d-block w-100" alt="...">
                            </div>
                            <div class="carousel-item">
                              <img src="../assets/members/artamkin/certificate/certificate7.jpg" class="d-block w-100" alt="...">
                            </div>
                            <div class="carousel-item">
                              <img src="../assets/members/artamkin/certificate/certificate8.jpg" class="d-block w-100" alt="...">
                            </div>
                            <div class="carousel-item">
                              <img src="../assets/members/artamkin/certificate/certificate9.jpg" class="d-block w-100" alt="...">
                            </div>
                            <div class="carousel-item">
                              <img src="../assets/members/artamkin/certificate/certificate10.jpg" class="d-block w-100" alt="...">
                            </div>
                            <div class="carousel-item">
                              <img src="../assets/members/artamkin/certificate/certificate11.jpg" class="d-block w-100" alt="...">
                            </div>
                            <div class="carousel-item">
                              <img src="../assets/members/artamkin/certificate/certificate12.jpg" class="d-block w-100" alt="...">
                            </div>
                            <div class="carousel-item">
                              <img src="../assets/members/artamkin/certificate/certificate13.jpg" class="d-block w-100" alt="...">
                            </div>
                            <div class="carousel-item">
                              <img src="../assets/members/artamkin/certificate/certificate14.jpg" class="d-block w-100" alt="...">
                            </div>
                            <div class="carousel-item">
                              <img src="../assets/members/artamkin/certificate/certificate15.jpg" class="d-block w-100" alt="...">
                            </div>
                            <div class="carousel-item">
                              <img src="../assets/members/artamkin/certificate/certificate16.jpg" class="d-block w-100" alt="...">
                            </div>
                          </div>
                          <a class="carousel-control-prev" href="#modalCertificateSlideAA" role="button" data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Предыдущий</span>
                          </a>
                          <a class="carousel-control-next" href="#modalCertificateSlideAA" role="button" data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Следующий</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- КОВАЛЬ ИЛЬЯ НИКОЛАЕВИЧ -->
    <div class="card mb-3">
      <div class="row no-gutters">
        <div class="col-md-4 infoCard">
          <i class="fas fa-user-circle"></i>
          <h4 class="card-title" style="margin-top: 20px">Коваль Илья Николаевич</h4>
          <p class="card-text text-muted">Заместитель генерального директора</p>
        </div>
        <div class="col-md-8">
          <div class="card-body row">
            <div class="col-md-6">
              <h5>Объекты</h5>
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th scope="col">Объект</th>
                    <th scope="col">Работы</th>
                  </tr>
                  </thead>
                  <tbody>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-md-6">
              <h5>Сертификаты</h5>
              <div id="certificateSlideKI" class="carousel-style carousel-fade carousel slide" data-ride="carousel" data-pause="false">
                <div class="carousel-inner" data-toggle="modal" data-target="#certificateModalKI">
                  <div class="carousel-item active">
                    <img src="../assets/members/koval/certificate/certificate1.jpg" class="d-block w-100 certificate" alt="...">
                  </div>
                  <div class="carousel-item">
                    <img src="../assets/members/koval/certificate/certificate2.jpg" class="d-block w-100 certificate" alt="...">
                  </div>
                </div>
                <!-- Модальное окно Сертификатов -->
                <div class="modal fade" id="certificateModalKI" tabindex="-1" role="dialog" aria-labelledby="certificateModalLabelKI" aria-hidden="true">
                  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div class="modal-content">
                      <div class="modal-body">
                        <div id="modalCertificateSlideKI" class="carousel-fade carousel slide" data-ride="carousel">
                          <ol class="carousel-indicators">
                            <li data-target="#modalCertificateSlideKI" data-slide-to="0" class="active"></li>
                            <li data-target="#modalCertificateSlideKI" data-slide-to="1"></li>
                          </ol>
                          <div class="carousel-inner">
                            <div class="carousel-item active">
                              <img src="../assets/members/koval/certificate/certificate1.jpg" class="d-block w-100" alt="...">
                            </div>
                            <div class="carousel-item">
                              <img src="../assets/members/koval/certificate/certificate2.jpg" class="d-block w-100" alt="...">
                            </div>
                          </div>
                          <a class="carousel-control-prev" href="#modalCertificateSlideKI" role="button" data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Предыдущий</span>
                          </a>
                          <a class="carousel-control-next" href="#modalCertificateSlideKI" role="button" data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Следующий</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- ЧЕРНЯЕВ ДМИТРИЙ АЛЕКСАНДРОВИЧ -->
    <div class="card mb-3">
      <div class="row no-gutters">
        <div class="col-md-4 infoCard">
          <i class="fas fa-user-circle"></i>
          <h4 class="card-title" style="margin-top: 20px">Черняев Дмитрий Александрович</h4>
          <p class="card-text text-muted">Программист-наладчик</p>
        </div>
        <div class="col-md-8">
          <div class="card-body row">
            <div class="col-md-6">
              <h5>Объекты</h5>
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th scope="col">Объект</th>
                    <th scope="col">Работы</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>ЖК "Царская площадь"</td>
                    <td class="text-left">
                      <ul class="listExp">
                        <li>Техническое обслуживание общеобменной вентиляции</li>
                        <li>Техническое обслуживание ИТП</li>
                        <li>Техническое обслуживание противопожарной вентиляции</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>ЖК "Водный"</td>
                    <td class="text-left">
                      <ul class="listExp">
                        <li>Техническое обслуживание общеобменной вентиляции</li>
                        <li>Техническое обслуживание ИТП</li>
                        <li>Техническое обслуживание противопожарной вентиляции</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>ЖК "Маяковский"</td>
                    <td class="text-left">
                      <ul class="listExp">
                        <li>Техническое обслуживание общеобменной вентиляции</li>
                        <li>Техническое обслуживание ИТП</li>
                        <li>Техническое обслуживание противопожарной вентиляции</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>ЖК "Савеловский"</td>
                    <td class="text-left">
                      <ul class="listExp">
                        <li>Техническое обслуживание общеобменной вентиляции</li>
                        <li>Техническое обслуживание ИТП</li>
                        <li>Техническое обслуживание противопожарной вентиляции</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>ЖК "Двинцев, 14"</td>
                    <td class="text-left">
                      <ul class="listExp">
                        <li>Техническое обслуживание общеобменной вентиляции</li>
                        <li>Техническое обслуживание ИТП</li>
                        <li>Техническое обслуживание противопожарной вентиляции</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>ЖК "Гранатный, 6"</td>
                    <td class="text-left">
                      <ul class="listExp">
                        <li>Техническое обслуживание противопожарной системы</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>HAYATT Regency</td>
                    <td class="text-left">
                      <ul class="listExp">
                        <li>Техническое обслуживание общеобменной вентиляции</li>
                        <li>Техническое обслуживание ИТП</li>
                        <li>Техническое обслуживание противопожарной вентиляции</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Министерство Российской Федерации по развитию Дальнего Востока и Арктики</td>
                    <td class="text-left">
                      <ul class="listExp">
                        <li>Техническое обслуживание общеобменной вентиляции</li>
                        <li>Техническое обслуживание ИТП</li>
                        <li>Техническое обслуживание противопожарной вентиляции</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>ЖК "Дом в сосновой роще"</td>
                    <td class="text-left">
                      <ul class="listExp">
                        <li>Техническое обслуживание общеобменной вентиляции</li>
                        <li>Техническое обслуживание ИТП</li>
                        <li>Техническое обслуживание противопожарной вентиляции</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td class="text-left">
                      <ul class="listExp">
                        <li>Разработка сайта компании ООО "ДиСКА"</li>
                      </ul>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-md-6">
              <h5>Сертификаты</h5>
              <div id="certificateSlideCD" class="carousel-style carousel-fade carousel slide" data-ride="carousel" data-pause="false">
                <div class="carousel-inner" data-toggle="modal" data-target="#certificateModalCD">
                  <div class="carousel-item active">
                    <img src="../assets/members/chernyaev/certificate/certificate1.jpg" class="d-block w-100 certificate" alt="...">
                  </div>
                  <div class="carousel-item">
                    <img src="../assets/members/chernyaev/certificate/certificate2.jpg" class="d-block w-100 certificate" alt="...">
                  </div>
                  <div class="carousel-item">
                    <img src="../assets/members/chernyaev/certificate/certificate3.jpg" class="d-block w-100 certificate" alt="...">
                  </div>
                </div>
                <!-- Модальное окно Сертификатов -->
                <div class="modal fade" id="certificateModalCD" tabindex="-1" role="dialog" aria-labelledby="certificateModalLabelCD" aria-hidden="true">
                  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div class="modal-content">
                      <div class="modal-body">
                        <div id="modalCertificateSlideCD" class="carousel-fade carousel slide" data-ride="carousel">
                          <ol class="carousel-indicators">
                            <li data-target="#modalCertificateSlideCD" data-slide-to="0" class="active"></li>
                            <li data-target="#modalCertificateSlideCD" data-slide-to="1"></li>
                            <li data-target="#modalCertificateSlideCD" data-slide-to="2"></li>
                          </ol>
                          <div class="carousel-inner">
                            <div class="carousel-item active">
                              <img src="../assets/members/chernyaev/certificate/certificate1.jpg" class="d-block w-100" alt="...">
                            </div>
                            <div class="carousel-item">
                              <img src="../assets/members/chernyaev/certificate/certificate2.jpg" class="d-block w-100" alt="...">
                            </div>
                            <div class="carousel-item">
                              <img src="../assets/members/chernyaev/certificate/certificate3.jpg" class="d-block w-100" alt="...">
                            </div>
                          </div>
                          <a class="carousel-control-prev" href="#modalCertificateSlideCD" role="button" data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Предыдущий</span>
                          </a>
                          <a class="carousel-control-next" href="#modalCertificateSlideCD" role="button" data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Следующий</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- КОТКОВ МИХАИЛ АЛЕКСАНДРОВИЧ -->
    <div class="card mb-3">
      <div class="row no-gutters">
        <div class="col-md-4 infoCard">
          <i class="fas fa-user-circle"></i>
          <h4 class="card-title" style="margin-top: 20px">Котков Михаил Александрович</h4>
          <p class="card-text text-muted">Наладчик</p>
        </div>
        <div class="col-md-8">
          <div class="card-body row">
            <div class="col-md-6">
              <h5>Объекты</h5>
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th scope="col">Объект</th>
                    <th scope="col">Работы</th>
                  </tr>
                  </thead>
                  <tbody>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-md-6">
              <h5>Сертификаты</h5>
              <div id="certificateSlideKM" class="carousel-style carousel-fade carousel slide" data-ride="carousel" data-pause="false">
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <i class="far fa-image"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<style lang="scss">
  .expPersonal {
    .carousel-item {
      i {
        font-size: 150px;
        color: #ff5935;
      }
    }

    .infoCard {
      i {
        font-size: 300px;
        color: #ff5935;
      }
    }

    .card-img {
      border-radius: 50%;
      width: 300px;
      height: 300px;
    }
    tbody, tr, td, th { display: block; }

    tr:after {
      content: ' ';
      display: block;
      visibility: hidden;
      clear: both;
    }

    tbody {
      max-height: 250px;
      overflow-y: auto;
    }

    .carousel-style {
      .certificate:hover {
        opacity: 0.8
      }
      .certificate {
        max-height: 300px;
      }
      max-width: 200px;
      margin: auto;
    }

    tbody td, thead th {
      width: 50%;
      float: left;
      font-size: 0.8em;
    }
  }

</style>